import { Download, FilterAlt, Search, Upload } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import DateRangePicker from 'components/organisms/DateRangePicker';
import { REMOTE } from 'constants/serverRoutes';
import { format, parseISO, subDays } from 'date-fns';
import debounce from 'helpers/debounce';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { REFETCH } from 'utils/redux/actions';

const FilterList = (props) => {
  const { filters, filterDateRange, isButtonDisabled } = props;
  const DEFAULT_DAYS = filterDateRange || 7;
  const DEFAULT_SPAN = 1;
  const [appState, dispatch] = useStateValue();
  const { search } = useLocation();
  const { showNotification } = useNotification();
  const [filterState, setFilterState] = useState({
    usersInfo: [],
    ...filters
      .filter((f) => f.id)
      .reduce((state, field) => {
        state[field.id] = field.default || '';
        return state;
      }, {}),
    ...filters
      .filter((f) => f.id && f.type === 'typeAhead')
      .map((f) => f.id)
      .reduce((state, field) => {
        state[field + 'TypeOptions'] = [];
        return state;
      }, {}),
    ...filters
      .filter((f) => f.type === 'multiDropDown')
      .map((field) => field)
      .reduce((state, field) => {
        state[field.id + 'Value'] = field.default || [];
        return state;
      }, {}),
    startDate: format(subDays(new Date(), DEFAULT_SPAN), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  useEffect(() => onRouteChanged(), [search]);

  useEffect(() => {
    if (appState.refetch) {
      showAll();
      dispatch({ type: REFETCH, refetch: false });
    }
  }, [appState]);

  const onRouteChanged = () => {
    const queryState = getLocationSearch();
    const newFilterState = { ...filterState, ...queryState };
    setFilterState(newFilterState);
    handleSearch(queryState.activePage, newFilterState);
  };

  const getLocationSearch = () => {
    let pairs = search.slice(1).split('&');
    let result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
  };

  const handleFormatting = (e, id) => {
    if (e?.target?.value) {
      e.target.value = e.target.value.replace(/[^0-9,]/g, '');
    }
    handleChange(e, id);
  };

  const handleChange = (e, filterId, typeAheadValue) => {
    setFilterState((prevState) => ({
      ...prevState,
      [filterId]: typeAheadValue ? typeAheadValue.id : e.target.value || ''
    }));
    if (filterId == 'nik') {
      if (e.target?.value?.length == 16) {
        getUserIdByNik(e.target.value);
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          ['userId']: ''
        }));
      }
    }
    if (filterId === 'userId' && !typeAheadValue) {
      setFilterState((prevState) => ({
        ...prevState,
        userInfo: ''
      }));
    }
  };

  const handleMultiSelectChange = (e, filterId, options) => {
    const value = e.target.value;
    if (value[value.length - 1] === 'all') {
      setFilterState((prevState) => ({
        ...prevState,
        [filterId]:
          filterState[filterId].length === options.length
            ? []
            : options.map((option) => option.value)
      }));
      return;
    }
    setFilterState((prevState) => ({
      ...prevState,
      [filterId]: value || ''
    }));
  };

  const handleDateChange = (range) => {
    const startDate = format(range.startDate, 'yyyy-MM-dd');
    const endDate = format(range.endDate, 'yyyy-MM-dd');
    setFilterState((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const getResetFilter = () => {
    let setQuery = {};
    let query = {};
    filters.forEach((field) => {
      if (!(field.id === 'date' || field.id === undefined)) {
        query[field.id] = '';
        setQuery[field.id] = field.default || '';
      }
      if (field.type === 'typeAhead') {
        query[field.id + 'TypeOptions'] = [];
        setQuery[field.id + 'TypeOptions'] = [];
      }
      if (field.type === 'multiDropDown') {
        setQuery[field.id + 'Value'] = field.default;
        query[field.id] = field.default ? field.default.map((val) => val).join(',') : '';
        return;
      }
      if (field.type === 'dateRange') {
        query['startDate'] = format(subDays(new Date(), DEFAULT_SPAN), 'yyyy-MM-dd');
        query['endDate'] = format(new Date(), 'yyyy-MM-dd');

        setQuery['startDate'] = format(subDays(new Date(), DEFAULT_SPAN), 'yyyy-MM-dd');
        setQuery['endDate'] = format(new Date(), 'yyyy-MM-dd');
      }
      if (field.default) {
        query[field.id] = field.default === 'all' ? '' : field.default;
        setQuery[field.id] = field.default || '';
      }
    });
    document.querySelectorAll('.MuiAutocomplete-clearIndicator')?.forEach((ele) => {
      ele.click();
    });
    setFilterState(setQuery);
    return query;
  };

  const getUserIdByNik = (nik) => {
    makeRequest({
      url: `${REMOTE.KYC1}/filter`,
      params: {
        nik
      }
    }).then((result) => {
      if (result.data?.data?.length) {
        const userId = result.data.data.map((user) => user.userId).toString();
        setFilterState((prevState) => ({
          ...prevState,
          userId
        }));
      } else {
        showNotification('error', 'User Not Found');
      }
    });
  };

  const getFilterQuery = (page, newFilterState) => {
    newFilterState = newFilterState || filterState;
    let query = { activePage: page };
    let setQuery = {};
    filters.forEach((field) => {
      if (!field.id) return;
      if (field.type === 'dateRange') {
        query['startDate'] = newFilterState.startDate;
        query['endDate'] = newFilterState.endDate;
        return;
      }
      if (field.type === 'multiDropDown') {
        query[field.id] = newFilterState[field.id + 'Value']
          ? newFilterState[field.id + 'Value'].map((val) => val).join(',')
          : null;
        return;
      }
      if (field.func) {
        field.func(query, newFilterState[field.id], setQuery);
        return;
      }
      if (field.default) {
        query[field.id] = newFilterState[field.id];
        return;
      } else {
        query[field.id] =
          newFilterState[field.id] !== '' && newFilterState[field.id] !== field.default
            ? newFilterState[field.id]
            : null;
      }
    });
    setFilterState((prevState) => ({ ...prevState, ...setQuery }));
    return query;
  };

  const getTypeAheadData = (value, filterId, resource, labelFields, label) => {
    if (label === 'User Name/Email/Phone') {
      if (value.indexOf('||') < 0) {
        // FIX: Remove the comment if userInfo is required in filters
        // setFilterState((prevState) => ({
        //   ...prevState,
        //   userInfo: value
        // }));
        return makeRequest({
          url: `${REMOTE.USER}/filter`,
          params: {
            userInfo: value.substr(0, 100)
          }
        })
          .then((result) => {
            if (result.data?.data?.data?.length) {
              return result.data.data.data.map((d) => {
                return {
                  id: d.id,
                  label: `${d.name} || ${d.email} || ${d.phone}`
                };
              });
            }
          })
          .then((json) =>
            setFilterState((prevState) => ({ ...prevState, [filterId + 'TypeOptions']: json }))
          );
      }
    } else {
      return makeRequest({
        url: `${REMOTE[resource]}/filter`,
        params: { page: 1, limit: 10, q: value.substr(0, 100) }
      })
        .then((result) => {
          if (result.data && result.data.data) {
            return result.data.data.map((d) => {
              return {
                id: d.id,
                label: labelFields.map((f) => d[f]).join(' || ')
              };
            });
          }
          return [];
        })
        .then((json) =>
          setFilterState((prevState) => ({ ...prevState, [filterId + 'TypeOptions']: json }))
        );
    }
  };

  const handleSearch = (page, newFilterState) => {
    page = page || 1;
    let query = getFilterQuery(parseInt(page, 10), newFilterState);
    props.onSubmit(query);
  };

  const showAll = () => {
    let query = getResetFilter();
    props.onSubmit(query);
  };

  const onExport = () => {
    let query = getFilterQuery();
    props.onExport(query);
  };

  const onRegisterToKsei = () => {
    let query = getFilterQuery();
    props.onRegisterToKsei(query);
  };

  const exportButtons = () => (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {filters.find((f) => f.type === 'exportExcel') && (
        <Button
          color="success"
          size="small"
          variant="contained"
          startIcon={<Download />}
          disabled={isButtonDisabled}
          onClick={onExport}>
          Export Excel
        </Button>
      )}
      {filters.find((filter) => filter.type === 'registerToKsei') && (
        <Button
          color="error"
          size="small"
          variant="contained"
          startIcon={<Download />}
          disabled={isButtonDisabled}
          onClick={onRegisterToKsei}>
          Registered to Ksei
        </Button>
      )}
      {filters.find((filter) => filter.type === 'registerInKsei') && (
        <Link component={RouterLink} to="/mf/uploadMfStatus">
          <Button size="small" variant="contained" startIcon={<Upload />}>
            Registered In Ksei
          </Button>
        </Link>
      )}
    </Box>
  );

  const textFilter = ({ id, label, type }) => {
    return (
      <Grid key={id} item xs={filters.length > 2 ? 2 : 3}>
        <TextField
          fullWidth
          label={label}
          margin="normal"
          size="small"
          type={type}
          value={filterState[id]}
          onChange={(e) =>
            type === 'formattedNumber' ? handleFormatting(e, id) : handleChange(e, id)
          }
        />
      </Grid>
    );
  };

  const dropdownFilter = ({ id, label, options }) => (
    <Grid key={id} item xs={filters.length > 2 ? 2 : 3}>
      <TextField
        fullWidth
        label={label}
        margin="normal"
        size="small"
        select
        InputLabelProps={{ shrink: true }}
        SelectProps={{ displayEmpty: true }}
        value={filterState[id]}
        onChange={(e) => handleChange(e, id)}>
        {options.map((option, idx) => (
          <MenuItem key={`${idx}_${option.value}`} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );

  const multiDropDownFilter = ({ id, label, options }) => {
    const isAllSelected = options.length > 0 && filterState[id + 'Value'].length === options.length;
    return (
      <Grid key={id} item xs={filters.length > 2 ? 2 : 3}>
        <TextField
          fullWidth
          label={label}
          margin="normal"
          size="small"
          select
          value={filterState[id + 'Value'] || []}
          onChange={(e) => handleMultiSelectChange(e, id + 'Value', options)}
          SelectProps={{
            multiple: true,
            renderValue: (selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.length < 3 ? (
                  selected?.map((value) => <Chip key={value} label={value} />)
                ) : (
                  <Chip label={`${selected.length} selected`} />
                )}
              </Box>
            )
          }}>
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                filterState[id + 'Value'].length > 0 &&
                filterState[id + 'Value'].length < options.length
              }
            />
            Select All
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.name} value={option.value}>
              <Checkbox checked={filterState[id + 'Value']?.includes(option.value)} />
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  };

  const typeAheadFilter = ({ id, label, resource, labelFields }) => (
    <Grid key={id} item xs={filters.length > 2 ? 2 : 3}>
      <Autocomplete
        clearOnBlur={false}
        options={filterState[id + 'TypeOptions'] || []}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onInputChange={debounce((e, newInputValue) =>
          getTypeAheadData(newInputValue, id, resource, labelFields, label)
        )}
        onChange={(e, newValue) => handleChange(e, id === 'userInfo' ? 'userId' : id, newValue)}
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label={label} />
        )}
      />
    </Grid>
  );

  const autocompleteFilter = ({ id, label, options }) => (
    <Grid key={id} item xs={filters.length > 2 ? 2 : 3}>
      <Autocomplete
        clearOnBlur={false}
        options={options}
        onChange={(e, option) =>
          setFilterState((prevState) => ({ ...prevState, [id]: option?.value }))
        }
        renderInput={(params) => (
          <TextField {...params} margin="normal" size="small" label={label} />
        )}
      />
    </Grid>
  );

  const dateRangeFilter = ({ id }) => (
    <Grid key={id} item>
      <DateRangePicker
        initialDateRange={{
          startDate: parseISO(filterState.startDate),
          endDate: parseISO(filterState.endDate)
        }}
        defaultAllowedDays={DEFAULT_DAYS}
        onChange={handleDateChange}
      />
    </Grid>
  );

  const showFilters = (field) => {
    switch (field.type) {
      case 'text':
      case 'number':
      case 'formattedNumber':
        return textFilter(field);
      case 'dropdown':
        return dropdownFilter(field);
      case 'multiDropDown':
        return multiDropDownFilter(field);
      case 'typeAhead':
        return typeAheadFilter(field);
      case 'dateRange':
        return dateRangeFilter(field);
      case 'autocomplete':
        return autocompleteFilter(field);
      default:
        break;
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Filter</Typography>
        {exportButtons()}
      </Box>
      {filters?.length > 0 && (
        <Grid container component="form" spacing={2} alignItems="center">
          {filters.map((field) => showFilters(field))}
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => handleSearch(1)}
                startIcon={<Search />}>
                Search
              </Button>
              <Button
                color="info"
                size="small"
                variant="contained"
                onClick={showAll}
                startIcon={<FilterAlt />}>
                Reset Filters
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

FilterList.propTypes = {
  isLoading: PropTypes.bool,
  filters: PropTypes.array.isRequired,
  filterDateRange: PropTypes.number,
  onSubmit: PropTypes.func,
  onExport: PropTypes.func,
  onEmailExport: PropTypes.func,
  onRegisterToKsei: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  resource: PropTypes.string
};

export default FilterList;
