/*
 * Created by Rohit on 11/02/20
 */
let String = {};

String.toCapitalize = (text) => {
  // To Capitalize the first character of String
  if (text && typeof text === 'string') {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
};

String.toCamelCase = (text) => {
  if (text && typeof text === 'string') {
    return text
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '');
  }
  return text;
};

String.toTitleCase = (text) => {
  if (text && typeof text === 'string') {
    return text.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  return text;
};

String.toFormattedCurrency = (
  amount,
  {
    prefix = 'Rp',
    locale = 'en-US',
    showPrefix = false,
    showSign = false,
    compact = false,
    compactFormat = 'short',
    maximumFractionDigits = 2,
    minimumFractionDigits = 0
  }
) => {
  const numberAmount = Number(amount);
  if (Number.isNaN(numberAmount)) return amount;

  const abs = Math.abs(numberAmount);
  let formatted = abs.toLocaleString(locale, { maximumFractionDigits, minimumFractionDigits });
  if (compact && abs > 0) {
    const compactMap = [
      {
        short: '',
        long: ''
      },
      {
        short: 'rb',
        long: 'ribu'
      },
      {
        short: 'jt',
        long: 'juta'
      },
      {
        short: 'M',
        long: 'Miliar'
      },
      {
        short: 'T',
        long: 'triliun'
      }
    ];
    const k = 1000;
    let i = Math.floor(Math.log(abs) / Math.log(k));
    if (i >= compactMap.length) i = compactMap.length - 1;
    const abb = parseFloat(abs / k ** i).toLocaleString(locale, {
      maximumFractionDigits,
      minimumFractionDigits
    });
    formatted = `${abb} ${compactMap[i][compactFormat]}`;
  }

  formatted = formatted.trim();
  if (showPrefix) {
    if (showSign) {
      if (numberAmount === 0) return `${prefix}${formatted}`;
      return numberAmount > 0 ? `+${prefix}${formatted}` : `-${prefix}${formatted}`;
    }
    return numberAmount >= 0 ? `${prefix}${formatted}` : `-${prefix}${formatted}`;
  }
  if (showSign) {
    if (numberAmount === 0) return `${formatted}`;
    return numberAmount > 0 ? `+${formatted}` : `-${formatted}`;
  }
  return numberAmount >= 0 ? formatted : `-${formatted}`;
};

module.exports = String;
