import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

const CsvFilePreview = ({ headers, uploadData }) => {
  return (
    <>
      {uploadData.length > 0 && (
        <TableContainer>
          <Table sx={{ whiteSpace: 'nowrap' }}>
            <TableBody>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header} variant="head">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
              {uploadData.map((data, index) => {
                return (
                  <TableRow key={index}>
                    {headers.map((header) => (
                      <TableCell key={header}>{data[header]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

CsvFilePreview.propTypes = {
  headers: PropTypes.array,
  uploadData: PropTypes.array
};

export default CsvFilePreview;
