import {
  validateAumData,
  validateBagUploadApprovalData,
  validateBagUploadBalanceRequestData,
  validateBagUploadTransferData,
  validateBcaCallbackUploadData,
  validateBulkDividendData,
  validateDividendData,
  validateEDDUploadData,
  validatePepStatusUploadData,
  validateRefundUploadData,
  validateSInvestForeignerUploadData,
  validateSInvestUploadData,
  validateUploadEIPOData,
  validateUploadMFData,
  validateUploadNav,
  validateUserTagsData
} from 'helpers/validators';

const parseCSV = function (file, action = null) {
  return new Promise((resolve, reject) => {
    const jsonObject = [];

    const validateData = (rows, func) => {
      const { data, errors } = func(rows);
      if (errors.length) reject(errors);
      resolve(data);
    };

    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function (event) {
      let csv = event.target.result;

      let rows = csv.split('\r\n');
      if (rows.length === 1) {
        rows = rows[0].split('\n');
      }
      if (rows.length === 1) {
        reject('Empty file.');
      }
      if (action === 'uploadMf' && !validateUploadMFData(rows)) {
        reject('Invalid data format.');
      }
      if (action === 'uploadMfNav' && !validateUploadNav(rows)) {
        reject('Invalid data format.');
      }
      if (action === 'uploadMfAum' && !validateAumData(rows)) {
        reject('Invalid data format');
      }
      if (action === 'uploadMfDividend' && !validateDividendData(rows)) {
        reject('Invalid data format');
      }
      if (action === 'uploadEIPO' && !validateUploadEIPOData(rows)) {
        reject('Invalid data format');
      }
      if (action === 'uploadUserTags' && !validateUserTagsData(rows)) {
        reject('Wrong header row id');
      }
      if (action === 'uploadPepStatus') validateData(rows, validatePepStatusUploadData);
      if (action === 'uploadRefund') validateData(rows, validateRefundUploadData);
      if (action === 'uploadEdd') validateData(rows, validateEDDUploadData);
      if (action === 'uploadBulkDividend') validateData(rows, validateBulkDividendData);
      if (action === 'sInvestUpload') validateData(rows, validateSInvestUploadData);
      if (action === 'uploadBcaCallback') validateData(rows, validateBcaCallbackUploadData);
      if (action === 'sInvestForeignerUpload')
        validateData(rows, validateSInvestForeignerUploadData);
      if (action === 'uploadTransfer') validateData(rows, validateBagUploadTransferData);
      if (action === 'uploadApproval') validateData(rows, validateBagUploadApprovalData);
      if (action === 'uploadBalanceRequest')
        validateData(rows, validateBagUploadBalanceRequestData);
      let headers = rows[0].split(',');

      let data = [];

      for (let i = 1; i < rows.length; i++) {
        let cols = rows[i].split(',');
        let dataElement = {};
        for (let j = 0; j < cols.length; j++) {
          dataElement[headers[j]] = cols[j];
        }
        data.push(dataElement);
      }
      jsonObject.push({ data: JSON.stringify(data) });
      resolve(jsonObject);
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export default parseCSV;
