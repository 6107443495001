import { Upload } from '@mui/icons-material';
import { Button, Input } from '@mui/material';
import PropTypes from 'prop-types';

const UploadFile = ({ onChange, uploadBtnText, acceptedFileTypes, disabled, fileName, color }) => {
  return (
    <Button color={color} variant="contained" component="label" size="small" startIcon={<Upload />}>
      {fileName || uploadBtnText}
      <Input
        sx={{ display: 'none' }}
        onChange={onChange}
        inputProps={{
          accept: acceptedFileTypes
        }}
        disabled={disabled}
        type="file"
        onClick={(event) => (event.target.value = null)}
      />
    </Button>
  );
};

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  uploadBtnText: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  disabled: PropTypes.bool,
  fileName: PropTypes.string,
  color: PropTypes.string
};

UploadFile.defaultProps = {
  uploadBtnText: 'Upload File',
  acceptedFileTypes: null,
  fileName: '',
  color: 'primary'
};

export default UploadFile;
