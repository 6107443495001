export const BAG_MANUAL_TRANSFERS_TRANSACTION_TYPE = Object.freeze({
  PCA_PGB: 'PCA_PGB',
  PCA_PES: 'PCA_PES',
  PES_PGB: 'PES_PGB',
  PGB_PES: 'PGB_PES'
});

export const BAG_MANUAL_TRANSFERS_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
});

export const BAG_MANUAL_TRANSFERS_ACCESS_ROLES = Object.freeze({
  SUPER_ADMIN: 'SuperAdmin',
  FINANCE_MANAGER: 'Finance Manager'
});
